import Favicon from "../src/assets/images/favicon.ico"
import Nestlypay1 from "../src/assets/images/nestlypay2.png"
import P2PFi from "../src/assets/images/p2pfi.png"

const logotext = "VICTOR EDIDIONG";
const meta = {
    headerTitle: "iamEdDy",
    headerImg: Favicon,
    title: "Victor EdDy",
    description: "I’m Victor EdDy, data scientist _ Full stack devloper, currently working in Berlin",
};

const introdata = {
    title: "I’m Victor Eddy",
    animated: {
        first: "I see.",
        second: "I code.",
        third: "I fix.",
    },
    description: "Here, its just me, dedicating my life to finding the easiest routes to programmable solutions",
    your_img_url: "https://images.unsplash.com/photo-1514790193030-c89d266d5a9d",
};

const dataabout = {
    title: "A bit about my self",
    aboutme: "Here again, its me still finding easier solutions to problems. I love to show y'all how we can work together in achieving this, if you would love to join me, please contact me. Asides that, I am just being me, motivated by me to solve everyone's problems.",
};
const worktimeline = [{
        jobtitle: "Blockchain Developer",
        where: "Groomlog LTD",
        date: "2022",
    },
    {
        jobtitle: "Lead Front-end Engineer",
        where: "Groomlog LTD",
        date: "2020",
    },
    {
        jobtitle: "Lead Fullstack Engineer",
        where: "Nsisong Labs",
        date: "2019",
    },
    {
        jobtitle: "Front-end Engineer",
        where: "Nsisong Labs",
        date: "2018",
    },
];

const skills = [{
        name: "Python",
        value: 80,
    },
    {
        name: "PHP",
        value: 90,
    },
    {
        name: "Javascript",
        value: 95,
    },
    {
        name: "React",
        value: 98,
    },
    {
        name: "Jquery",
        value: 85,
    },
];

const services = [{
        title: "UI & UX Design",
        description: "Easily, I bring in the best web interactions and experience in my designs and I'll love you try me out.",
    },
    {
        title: "Mobile Apps",
        description: "I enjoy making apps to help people easily access solutions to very complex tasks with mobile devices.",
    },
    {
        title: "Web Design & Development",
        description: "What do you know about this, well, all I know is users on any web platform i build don't leave the same way.",
    },
    {
        title: "Blockchain Development",
        description: "Its always been a pleasure doing outrageous things to solve complex problems, Blockchain development is one of my best ways.",
    },
];

const dataportfolio = [{
        // img: "https://picsum.photos/400/?grayscale",
        img: P2PFi,
        desctiption: "P2P Finance Limited.",
        link: "https://p2pfi.co",
    },
    {
        // img: "https://picsum.photos/400/800/?grayscale",
        img: Nestlypay1,
        desctiption: "NestlyPay Limited.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        desctiption: "P2PFi Academy.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/600/?grayscale",
        desctiption: "Groomlog Limited.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/300/?grayscale",
        desctiption: "SushiSwap Explorer.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/700/?grayscale",
        desctiption: "iVerify Services.",
        link: "#",
    },

    // {
    //     img: "https://picsum.photos/400/600/?grayscale",
    //     desctiption: "The wisdom of life consists in the elimination of non-essentials.",
    //     link: "#",
    // },
    // {
    //     img: "https://picsum.photos/400/300/?grayscale",
    //     desctiption: "The wisdom of life consists in the elimination of non-essentials.",
    //     link: "#",
    // },
    // {
    //     img: "https://picsum.photos/400/?grayscale",
    //     desctiption: "The wisdom of life consists in the elimination of non-essentials.",
    //     link: "#",
    // },
    // {
    //     img: "https://picsum.photos/400/550/?grayscale",
    //     desctiption: "The wisdom of life consists in the elimination of non-essentials.",
    //     link: "#",
    // },
    // {
    //     img: "https://picsum.photos/400/?grayscale",
    //     desctiption: "The wisdom of life consists in the elimination of non-essentials.",
    //     link: "#",
    // },
    // {
    //     img: "https://picsum.photos/400/700/?grayscale",
    //     desctiption: "The wisdom of life consists in the elimination of non-essentials.",
    //     link: "#",
    // },
];

const contactConfig = {
    YOUR_EMAIL: "iamvictoremmanuel03@gmail.com",
    YOUR_FONE: "+234 (913)504-2728",
    description: "If you'd love to join me in creating easier solutions to the most complex problems, hit me up. Any other enquiries are welcomed too.",
    // create an emailjs.com account 
    // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
    YOUR_SERVICE_ID: "service_4cx9ctq",
    YOUR_TEMPLATE_ID: "template_m9idoip",
    YOUR_USER_ID: "Yq2yKflqPlQKeSJ2L",
};

const socialprofils = {
    github: "https://github.com/iamEdDy",
    // facebook: "https://facebook.com/",
    linkedin: "https://linkedin.com/in/victoredidiong",
    twitter: "https://twitter.com/edidiong_v",
    // youtube: "https://youtube.com",
    // twitch: "https://twitch.com",
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};
